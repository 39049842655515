// JavaScript Document

//VARIABLES GLOBALES //////////////////////////////////////////////
var bH, bW 				= null;
var lan 				= "en";
var breakpoint_sm 		= 0;
var breakpoint_md 		= 640;
var breakpoint_lg 		= 1024;
var breakpoint_xlg 		= 1200;
var breakpoint_xxlg		= 1440;




jQuery(function($){
	
	///////////////////////////////////////////////////////////////
	//FUNCIONES GLOBALES //////////////////////////////////////////
	///////////////////////////////////////////////////////////////

	// Browser size ***********************************************
	function browserSize(){
		bW = $(window).width();
		bH = $(window).height();
	}


	// Background images ******************************************
	function bkg() {
		$("[data-bkg]").each(function() {
			$(this).css("background-image", "url('" + $(this).data("bkg") + "')");
		});
	}


	// Multiple background images *********************************
	function bkgmulti() {
		$("[bkgmulti]").each(function() {
			var bkgsm =  $(this).data("bkg-sm");
			var bkgmd =  $(this).data("bkg-md");
			var bkglg =  $(this).data("bkg-lg");

			var bkgimg = bkgsm;
			
			if ( bW < breakpoint_sm ) {
				bkgimg = bkgsm;
			}
			
			if ( bW >= breakpoint_sm && bW < breakpoint_md ) {
				bkgimg = bkgmd;
			}

			if ( bW >= breakpoint_md ) {
				bkgimg = bkglg;
			}

			$(this).css("background-image", "url('" + bkgimg + "')");
		});
	}


	// Language ***************************************************
	function languaje() {
		lan = $("html").attr("lang");
	}

		
	// Off Canvas Toggle **************************************
	function offCanvasToggle() {
		if (bW >= breakpoint_lg) {
			$(".header-menu").foundation("close", function(){
				$(".header-menu").foundation("destroy");				
			});
		} else {
			var offCanvas = new Foundation.OffCanvas($(".header-menu"));
		}
	}



	// Video player ***********************************************
	function videoPlayer(cv) {
		
		var videoTitle = $(cv).data("title");
		var videoDesc = $(cv).data("desc");
		var videoFile = $(cv).data("media");

		if ( typeof videoTitle === "undefined" || videoTitle === "" ) {
			videoTitle = " ";
		}

		if ( typeof videoDesc === "undefined" || videoDesc === "" ) {
			videoDesc = " ";
		}

		var labelVideoNotFound = "Video not found";
		if (lan !== "en") {
			labelVideoNotFound = "Video no encontrado";
		}

		if ( typeof videoFile === "undefined" || videoFile === "" ) {
			dialog = "<h2>" + labelVideoNotFound + "</h2>";
		} else {
			var mediaFormat = videoFile.slice(-4);
			var mimeType;

			switch(mediaFormat) {
				case ".ogg":
					mimeType = "video/ogg";
					break;
				case "webm":
					mimeType = "video/webm";
					break;
				default:
					mimeType = "video/mp4";
					break;
			}

			dialog ='<div id="video" class="dialog">' +
						'<button class="mfp-close mef-cross"></button>' +
						'<video id="media" controls preload="true">' +
							'<source src="' + videoFile + '" type="' + mimeType + '"/>' +
							'Your browser does not support the audio tag.' +
						'</video>' +
						'<figcaption>' +
							'<div class="mfp-bottom-bar">' +
								'<div class="mfp-title">' +
									'<h5 class="title">' + videoTitle + '</h5>' + '<small>' + videoDesc +'</small>' +
									//'<p>' + videoTitle + '</p>' +
								'</div>' +
							'</div>' +
						'</figcaption>' +
					'</div>';
		}

		$.magnificPopup.open({
			items: {
				src: dialog,
				type: 'inline'
			},
			preloader: true,
			callbacks: {
				beforeOpen: function() {
					$("body").toggleClass("no-scroll");
				},
				open: function() {
					var v = document.getElementById("media");
					var vW, vH;
					v.addEventListener( "loadedmetadata", function () {  // function (event)
						vW = this.videoWidth;
						vH = this.videoHeight;
						$( "#video" )
							.css( "max-width", vW + "px" )
							.position({ my:"center", at:"center", of:"window" });
					}, false );

					$("#video").animate({ opacity: 1 }, 400);
				},
				close: function() {
					$("body").toggleClass("no-scroll");
				}
			}
		});
	}


	///////////////////////////////////////////////////////////////
	//FUNCIONES ON DOCUMENT READY /////////////////////////////////
	///////////////////////////////////////////////////////////////
	$(document).ready(function() {

		browserSize();

		languaje();

		bkg();

		bkgmulti();

		offCanvasToggle();


		// Form validation ****************************************
		// $("#msg .ok").click(function() {
		// 	$("#contact-form").trigger("reset").attr("data-send", "none");
		// 	$("#contact-form #fname").focus();
		// });

		// $("#msg .error").click(function() {
		// 	$("#contact-form").attr("data-send", "none");
		// 	$("#contact-form #fname").focus();
		// });


		// Carusel de Home Banner *********************************
		$("#home-banner").slick({
			speed: 1000,
			slide: ".item",
			infinite: true,
			slidesToShow: 1,
			autoplay: true,
			lazyLoad: 'ondemand',
			dots: true
		});


		// Carusel de Advertising *********************************
		$("#ad").slick({
			dots: false,
			speed: 1000,
			slide: ".item",
			infinite: true,
			slidesToShow: 1,
			variableWidth: true,
			autoplay: true,
			centerMode: true
		});


		// Carusel de Product Gallery *****************************
		$("#product-gallery").slick({
			dots: true,
			speed: 1000,
			slide: ".item",
			slidesToShow: 1,
			infinite: false,
			slidesToScroll: 1,
			variableWidth: true,
			responsive: [
				{
					breakpoint: breakpoint_md,
					settings: {
						variableWidth: false
					}
				}
			]
		});


		// Carusel de Fetured Products ****************************
		$("#fp").slick({
			dots: true,
			slide: ".item",
			infinite: false,
			slidesToShow: 3,
			slidesToScroll: 1,
			variableWidth: false,
			autoplay: false,
			centerMode: false,
			responsive: [
				{
					breakpoint: breakpoint_lg,
					settings: {
						slidesToShow: 2
					}
				},
				{
					breakpoint: breakpoint_md,
					settings: {
						slidesToShow: 1
					}
				}
			]
		});


		// Search *************************************************
		$("#search-triger").click(function(){
			$("#search-box").toggleClass("active");
		});


		// Product Detail Image Gallery ***************************
		$(".item.image > a").magnificPopup({
			tLoading: 'Loading image #%curr%...',
			type: 'image',
			closeMarkup: '<button class="mfp-close mef-cross"></button>',
			gallery:{
				enabled:true,
				preload: [0,1],
				navigateByImgClick: true,
				arrowMarkup: '<button title="%title%" type="button" class="mfp-arrow mfp-arrow-%dir%"></button>',
				tCounter: '<span class="mfp-counter">%curr% of %total%</span>'
			},
			image: {
				tError: '<a href="%url%">The image #%curr%</a> could not be loaded.',
				titleSrc: function(item) {
					return '<h5 class="title">' + item.el.data("title") + '</h5>' + '<small>' + item.el.data("desc") +'</small>';
				}
			}
		});


		// About Us Image Gallery *********************************
		$(".image-gallery").magnificPopup({
			tLoading: 'Loading image #%curr%...',
			delegate: "a",
			type: 'image',
			closeMarkup: '<button class="mfp-close mef-cross"></button>',
			gallery:{
				enabled:true,
				preload: [0,1],
				navigateByImgClick: true,
				arrowMarkup: '<button title="%title%" type="button" class="mfp-arrow mfp-arrow-%dir%"></button>',
				tCounter: '<span class="mfp-counter">%curr% of %total%</span>'
			},
			image: {
				tError: '<a href="%url%">The image #%curr%</a> could not be loaded.',
				titleSrc: function(item) {
					return '<h5 class="title">' + item.el.data("title") + '</h5>' + '<small>' + item.el.data("desc") +'</small>';
				}
			}
		});


		//-- Products list ****************************************
		var list = $("#products");
		var filter = $(list).data("filter");
		var pages = $(list).data("items-per-page");
		var pager = $(list).data("page-driver");
		var pageStart = 0;
		var itemsGroup;

		// Get Selected Product Category and Use type
		function filters() {
			//-- Category Filter
			var category = $(filter).find("#category option:selected").val();
			var categoryItems = $(list).find("[data-category]");
			if ( category != 0 ) {
				categoryItems = $(list).find("[data-category='" + category + "']");
			}

			//-- Type Filter
			var type = $(filter).find("#usetype option:selected").val();
			var filteredItems = $(categoryItems);
			if ( type != 0 ) {
				if ( category == 0 ) {
					filteredItems = $(list).find("[data-type='" + type + "']");
				} else {
					filteredItems = $(list).find("[data-category='" + category + "'][data-type='" + type + "']");
				}
			}

			pageStart = 0;

			return(filteredItems);
		}

		// Show products blocks
		function showProducts() {
			$(list).find(".item").attr("data-visible", "false");
			itemsGroup = filters();

			if ( pages >= $(itemsGroup).length ) {
				pages = $(itemsGroup).length;
				$(pager).attr("data-visible", "false");
			} else {
				pages = $(list).data("items-per-page");
				$(pager).attr("data-visible", "true");
			}

			for ( var i = pageStart; i < (pageStart + pages); i++ ) {
				$(itemsGroup).eq(i).attr("data-visible", "true");
			}

			pageStart = pageStart + pages;
			console.log("pageStart: " + pageStart + " , itemsGroup: " + $(itemsGroup).length + " , itemsPerPage: " + pages);
		}

		// Pagination
		$(pager).click(function(){
			//var hiddenItems = $(itemsGroup).map("[data-visible='false']");
			//console.log($(hiddenItems).length);
		});

		// Products pagination
		//-- First load - Show products
		showProducts();

		//-- Filter update
		$(filter).find("select").change(function(){
			showProducts();
		});

		//-- Filter icon update
		$("#usetype").change(function(){
			var icon = $("#usetype option:selected").data("icon");
			//console.log(icon);
			if ( icon == "no-icon" ) {
				$("#usetype").closest(".filter-item").addClass("no-icon");
				$("#usetype").siblings(".icon").find("span").attr("class", "");
			} else {
				$("#usetype").closest(".filter-item").removeClass("no-icon");
				$("#usetype").siblings(".icon").find("span").attr("class", icon);
			}
		});

	});





	///////////////////////////////////////////////////////////////
	//FUNCIONES ON WINDOW LOAD ////////////////////////////////////
	///////////////////////////////////////////////////////////////
	$(window).load(function() {

		// Scrollbar **********************************************
		// $(".scroll").mCustomScrollbar({
		// 	axis: "y",
		// 	theme: "minimal-dark",
		// 	scrollInertia: 0
		// });


		// Video player *******************************************
		$("[data-dialog=video]").click(function() {
			var sv = $(this);
			videoPlayer(sv);
		});


		// Image gallery ******************************************
		$("[data-dialog=image]").click(function() {
			var g = $(this).closest(".gallery");
			gallerySlideshow(g);
		});


		// Dialog for detail **************************************
		// $("[data-dialog]").click(function() {
		// 	//event.preventDefault();

		// 	//** Idioma
		// 	var labelBuy 		= "Add to cart";
		// 	var downloadLabel	= "Download Product Info";
		// 	var contactLabel	= "Contact ";
		// 	var readMoreLabel	= "Read more";

		// 	if (lan === "es") {
		// 		labelBuy 		= "Añadir al carrito";
		// 		downloadLabel	= "Descargar Información del Producto";
		// 		contactLabel	= "Contactar a ";
		// 		readMoreLabel	= "Leer m&aacute;s";
		// 	}

		// 	if (lan === "de") {
		// 		labelBuy 		= "In den Warenkorb";
		// 		downloadLabel	= "Downloaden Produkt-Information";
		// 		contactLabel	= "Kontakt ";
		// 		readMoreLabel	= "Mehr lesen";
		// 	}

		// 	//** Datos simples
		// 	var dataDialogTitle = $(this).closest("[data-dialogtitle]").data("dialogtitle");
		// 	var dataDialog 		= $(this).data("dialog");
		// 	var dataTitle 		= $(this).data("title");
		// 	var dataDesc 		= $(this).data("desc");
		// 	var dataImg 		= $(this).data("img");
		// 	var dataDate 		= $(this).data("date");
		// 	var dataPrice 		= $(this).data("price");
		// 	var dataUrl 		= $(this).data("url");
		// 	var dataPdf 		= $(this).data("pdf");
		// 	var dataEmail 		= $(this).data("email");

		// 	if ( typeof dataDialogTitle === "undefined" || dataDialogTitle === "" ) {
		// 		dataDialogTitle = " ";
		// 	} else {
		// 		dataDialogTitle = '<h4>' + dataDialogTitle + '</h4>';
		// 	}

		// 	if ( typeof dataTitle === "undefined" || dataTitle === "" ) {
		// 		dataTitle = " ";
		// 	}

		// 	if ( typeof dataDesc === "undefined" || dataDesc === "" ) {
		// 		dataDesc = " ";
		// 	} else {
		// 		dataDesc = '<div class="desc">' + dataDesc + '</div>';
		// 	}

		// 	if ( typeof dataImg === "undefined" || dataImg === "" ) {
		// 		dataImg = " ";
		// 	} else {
		// 		dataImg = '<div class="detailPane-cover" style="background-image: url(' + dataImg + ');"></div>';
		// 	}

		// 	if ( typeof dataDate === "undefined" || dataDate === "" ) {
		// 		dataDate = " ";
		// 	} else {
		// 		dataDate = '<p class="date">' + dataDate + '</p>';
		// 	}

		// 	if ( typeof dataUrl === "undefined" || dataUrl === "" ) {
		// 		dataUrl = " ";
		// 	} else {
		// 		dataUrl = 	'<a href="' + dataUrl + '" class="opacity transition more-link">' +
		// 						'<span class="icon-plus"></span>' + 
		// 						'<span class="label">' + readMoreLabel + '</span>' +
		// 					'</a>';
		// 	}

		// 	if ( typeof dataPrice === "undefined" || dataPrice === "" ) {
		// 		dataPrice = " ";
		// 	} else {
		// 		dataPrice = '<a class="btn btn-success price-link" href="' + dataUrl + '">' +
		// 						'<div class="roundLabelSmall">' +
		// 							'<span class="number">' + dataPrice +
		// 							'<span class="label">' + labelBuy + '</span>' +
		// 						'</div>' +
		// 					'</a>';
		// 	}

		// 	if ( typeof dataPdf === "undefined" || dataPdf === "" ) {
		// 		dataPdf = " ";
		// 	} else {
		// 		dataPdf = 	'<a href="' + dataPdf + '" class="pdf-link opacity transition">' +
		// 						'<span class="icon-pdf pdf"></span>' + 
		// 						'<span class="label">' + downloadLabel + '</span>' +
		// 					'</a>';
		// 	}

		// 	if ( typeof dataEmail === "undefined" || dataEmail === "" ) {
		// 		dataEmail = " ";
		// 	} else {
		// 		dataEmail = '<a href="mailto:' + dataEmail + '" class="email-link opacity transition">' +
		// 						'<span class="icon-envelope"></span>' + 
		// 						'<span class="label">' + contactLabel + dataTitle + '</span>' +
		// 					'</a>';
		// 	}

		// 	switch (dataDialog) {
		// 		case "detail":
		// 			//** Ensamblado de contenido
		// 			detail = '<h1 class="title">' + dataTitle + '</h1>' + dataPrice + dataDate + dataDesc + dataEmail + dataUrl + dataPdf; 

		// 			//** Acciones
		// 			//*** Eliminar Body scroll
		// 			$("body").addClass("noscroll noselect");

		// 			//*** Vaciar el panel de detalle y poner nuevo contenido
		// 			$("#detailPane").find(".detailPane-title").empty().append( dataDialogTitle );
		// 			$("#detailPane").find(".detailPane-cover").remove();
		// 			$("#detailPane").find(".detailPane-content-inner").before( dataImg );
		// 			$("#detailPane").find(".detailPane-content-inner").empty().append( detail );
		// 			$("#detailPane .scroll").mCustomScrollbar("scrollTo", "top");
					
		// 			//*** Abrir el panel de detalle
		// 			$("#detailPane").addClass("open");
		// 		break;
		// 	}
		// });

		//*** Cerrar el panel de detalle **************************
		// $(".detailPane .close").click(function() {
		// 	var pane = $(this).closest(".detailPane");
		// 	$(pane).removeClass("open");
		// 	$("body").removeClass("noscroll noselect");
		// });

	});





	///////////////////////////////////////////////////////////////
	//FUNCIONES ON WINDOW RESIZE //////////////////////////////////
	///////////////////////////////////////////////////////////////
	$(window).resize(function() {
		
		browserSize();

		bkgmulti();

		offCanvasToggle();

		// $.throttle( 250, function() {
		// });

	});



	
	
	///////////////////////////////////////////////////////////////
	//FUNCIONES ON DEVICE ORIENTATION CHANGE //////////////////////
	///////////////////////////////////////////////////////////////
	//$( window ).on( "orientationchange", function() {
	//});





	// $(document).mouseup(function (e) {
	//     var container = $(".menu.open");

	//     if (!container.is(e.target) // if the target of the click isn't the container...
	//         && container.has(e.target).length === 0) // ... nor a descendant of the container
	//     {
	// 		$("body").removeClass("noscroll");
	// 		$(".menu").removeClass("open");
	//     }
	// });
});











