// JavaScript Document

//VARIABLES GLOBALES //////////////////////////////////////////////
var bH, bW 				= null;
var lan 				= "en";
var breakpoint_sm 		= 0;
var breakpoint_md 		= 640;
var breakpoint_lg 		= 1024;
var breakpoint_xlg 		= 1200;
var breakpoint_xxlg		= 1440;




jQuery(function($){
	
	///////////////////////////////////////////////////////////////
	//FUNCIONES GLOBALES //////////////////////////////////////////
	///////////////////////////////////////////////////////////////

	// Browser size ***********************************************
	function browserSize(){
		bW = $(window).width();
		bH = $(window).height();
	}


	// Background images ******************************************
	function bkg() {
		$("[data-bkg]").each(function() {
			$(this).css("background-image", "url('" + $(this).data("bkg") + "')");
		});
	}


	// Multiple background images *********************************
	function bkgmulti() {
		$("[bkgmulti]").each(function() {
			var bkgsm =  $(this).data("bkg-sm");
			var bkgmd =  $(this).data("bkg-md");
			var bkglg =  $(this).data("bkg-lg");

			var bkgimg = bkgsm;
			
			if ( bW < breakpoint_sm ) {
				bkgimg = bkgsm;
			}
			
			if ( bW >= breakpoint_sm && bW < breakpoint_md ) {
				bkgimg = bkgmd;
			}

			if ( bW >= breakpoint_md ) {
				bkgimg = bkglg;
			}

			$(this).css("background-image", "url('" + bkgimg + "')");
		});
	}


	// Language ***************************************************
	function languaje() {
		lan = $("html").attr("lang");
	}

		
	// Off Canvas Toggle **************************************
	function offCanvasToggle() {
		if (bW >= breakpoint_lg) {
			$(".header-menu").foundation("close", function(){
				$(".header-menu").foundation("destroy");				
			});
		} else {
			var offCanvas = new Foundation.OffCanvas($(".header-menu"));
		}
	}



	// Video player ***********************************************
	function videoPlayer(cv) {
		
		var videoTitle = $(cv).data("title");
		var videoDesc = $(cv).data("desc");
		var videoFile = $(cv).data("media");

		if ( typeof videoTitle === "undefined" || videoTitle === "" ) {
			videoTitle = " ";
		}

		if ( typeof videoDesc === "undefined" || videoDesc === "" ) {
			videoDesc = " ";
		}

		var labelVideoNotFound = "Video not found";
		if (lan !== "en") {
			labelVideoNotFound = "Video no encontrado";
		}

		if ( typeof videoFile === "undefined" || videoFile === "" ) {
			dialog = "<h2>" + labelVideoNotFound + "</h2>";
		} else {
			var mediaFormat = videoFile.slice(-4);
			var mimeType;

			switch(mediaFormat) {
				case ".ogg":
					mimeType = "video/ogg";
					break;
				case "webm":
					mimeType = "video/webm";
					break;
				default:
					mimeType = "video/mp4";
					break;
			}

			dialog ='<div id="video" class="dialog">' +
						'<button class="mfp-close mef-cross"></button>' +
						'<video id="media" controls preload="true">' +
							'<source src="' + videoFile + '" type="' + mimeType + '"/>' +
							'Your browser does not support the audio tag.' +
						'</video>' +
						'<figcaption>' +
							'<div class="mfp-bottom-bar">' +
								'<div class="mfp-title">' +
									'<h5 class="title">' + videoTitle + '</h5>' + '<small>' + videoDesc +'</small>' +
									//'<p>' + videoTitle + '</p>' +
								'</div>' +
							'</div>' +
						'</figcaption>' +
					'</div>';
		}

		$.magnificPopup.open({
			items: {
				src: dialog,
				type: 'inline'
			},
			preloader: true,
			callbacks: {
				beforeOpen: function() {
					$("body").toggleClass("no-scroll");
				},
				open: function() {
					var v = document.getElementById("media");
					var vW, vH;
					v.addEventListener( "loadedmetadata", function () {  // function (event)
						vW = this.videoWidth;
						vH = this.videoHeight;
						$( "#video" )
							.css( "max-width", vW + "px" )
							.position({ my:"center", at:"center", of:"window" });
					}, false );

					$("#video").animate({ opacity: 1 }, 400);
				},
				close: function() {
					$("body").toggleClass("no-scroll");
				}
			}
		});
	}


	///////////////////////////////////////////////////////////////
	//FUNCIONES ON DOCUMENT READY /////////////////////////////////
	///////////////////////////////////////////////////////////////
	$(document).ready(function() {

		browserSize();

		languaje();

		bkg();

		bkgmulti();

		offCanvasToggle();


		// Form validation ****************************************
		// $("#msg .ok").click(function() {
		// 	$("#contact-form").trigger("reset").attr("data-send", "none");
		// 	$("#contact-form #fname").focus();
		// });

		// $("#msg .error").click(function() {
		// 	$("#contact-form").attr("data-send", "none");
		// 	$("#contact-form #fname").focus();
		// });


		// Carusel de Home Banner *********************************
		$("#home-banner").slick({
			speed: 1000,
			slide: ".item",
			infinite: true,
			slidesToShow: 1,
			autoplay: true,
			lazyLoad: 'ondemand',
			dots: true
		});


		// Carusel de Advertising *********************************
		$("#ad").slick({
			dots: false,
			speed: 1000,
			slide: ".item",
			infinite: true,
			slidesToShow: 1,
			variableWidth: true,
			autoplay: true,
			centerMode: true
		});


		// Carusel de Product Gallery *****************************
		$("#product-gallery").slick({
			dots: true,
			speed: 1000,
			slide: ".item",
			slidesToShow: 1,
			infinite: false,
			slidesToScroll: 1,
			variableWidth: true,
			responsive: [
				{
					breakpoint: breakpoint_md,
					settings: {
						variableWidth: false
					}
				}
			]
		});


		// Carusel de Fetured Products ****************************
		$("#fp").slick({
			dots: true,
			slide: ".item",
			infinite: false,
			slidesToShow: 3,
			slidesToScroll: 1,
			variableWidth: false,
			autoplay: false,
			centerMode: false,
			responsive: [
				{
					breakpoint: breakpoint_lg,
					settings: {
						slidesToShow: 2
					}
				},
				{
					breakpoint: breakpoint_md,
					settings: {
						slidesToShow: 1
					}
				}
			]
		});


		// // Carusel de Staff ****************************
		// $("#staff").slick({
		// 	dots: true,
		// 	slide: ".item",
		// 	infinite: false,
		// 	slidesToShow: 3,
		// 	slidesToScroll: 1,
		// 	variableWidth: false,
		// 	autoplay: false,
		// 	centerMode: false,
		// 	responsive: [
		// 		{
		// 			breakpoint: breakpoint_lg,
		// 			settings: {
		// 				slidesToShow: 2
		// 			}
		// 		},
		// 		{
		// 			breakpoint: breakpoint_md,
		// 			settings: {
		// 				slidesToShow: 1
		// 			}
		// 		}
		// 	]
		// });


		// Search *************************************************
		$("#search-triger").click(function(){
			$("#search-box").toggleClass("active");
			$(".newsletter-form").removeClass("active");
		});


		// Newsletter *********************************************
		$("#newsletter-triger").click(function(){
			$(".newsletter-form").toggleClass("active");
			$("#search-box").removeClass("active");
		});


		// Product Detail Image Gallery ***************************
		$(".item.image > a").magnificPopup({
			tLoading: 'Loading image #%curr%...',
			type: 'image',
			closeMarkup: '<button class="mfp-close mef-cross"></button>',
			gallery:{
				enabled:true,
				preload: [0,1],
				navigateByImgClick: true,
				arrowMarkup: '<button title="%title%" type="button" class="mfp-arrow mfp-arrow-%dir%"></button>',
				tCounter: '<span class="mfp-counter">%curr% of %total%</span>'
			},
			image: {
				tError: '<a href="%url%">The image #%curr%</a> could not be loaded.',
				titleSrc: function(item) {
					return '<h5 class="title">' + item.el.data("title") + '</h5>' + '<small>' + item.el.data("desc") +'</small>';
				}
			}
		});


		// About Us Image Gallery *********************************
		$(".image-gallery").magnificPopup({
			tLoading: 'Loading image #%curr%...',
			delegate: "a",
			type: 'image',
			closeMarkup: '<button class="mfp-close mef-cross"></button>',
			gallery:{
				enabled:true,
				preload: [0,1],
				navigateByImgClick: true,
				arrowMarkup: '<button title="%title%" type="button" class="mfp-arrow mfp-arrow-%dir%"></button>',
				tCounter: '<span class="mfp-counter">%curr% of %total%</span>'
			},
			image: {
				tError: '<a href="%url%">The image #%curr%</a> could not be loaded.',
				titleSrc: function(item) {
					return '<h5 class="title">' + item.el.data("title") + '</h5>' + '<small>' + item.el.data("desc") +'</small>';
				}
			}
		});


		// About Us Staff *********************************
		// var staffItem;

		// $(".staff").magnificPopup({
		// 	tLoading: 'Loading image #%curr%...',
		// 	delegate: "a",
		// 	closeMarkup: '<button class="mfp-close mef-cross"></button>',
		// 	gallery:{
		// 		enabled:true,
		// 		preload: [0,1],
		// 		navigateByImgClick: true,
		// 		arrowMarkup: '<button title="%title%" type="button" class="mfp-arrow mfp-arrow-%dir%"></button>',
		// 		tCounter: '<span class="mfp-counter">%curr% of %total%</span>'
		// 	},
		// 	callbacks: {
		// 		open: function() {
		// 			staffItem = this.currItem.el;
		// 			console.log($(staffItem).data("title"));
		// 			//staffDetail(this.currItem);
		// 		},
		// 		change: function() {
		// 			staffItem = this.currItem.el;
		// 			console.log($(staffItem).data("title"));
		// 			//staffDetail(this.currItem);
		// 		},
		// 		close: function() {
		// 			console.log("Closed");
		// 		}
		// 	},
		// 	items: {
		// 		src: '<div class="white-popup">Dynamically created popup</div>',
		// 		type: 'inline'
		// 	}
		// });


		//-- Products list ****************************************
		var list = $("#products");
		var items = $(list).find(".item");
		var filter = $(list).data("filter");
		var itemsPerPage = $(list).data("items-per-page");
		var pager = $(list).data("page-driver");
		var pageStart = 0;

		//-- Add class ".end" to the last element in the items list to overide Foundation behaviour
		$(items).last().toggleClass("end");

		// Get Selected Product Category and Use type
		function itemsFilter() {
			//-- Fiter selection
			var category = $(filter).find("#category option:selected").val();
			var type = $(filter).find("#usetype option:selected").val();

			//-- Build items group
			if ( category != 0 ) {
				items = $(items).filter("[data-category=" + category + "]");
			}
			
			// console.log("---------------------------");
			// console.log("Category: " + category);
			// console.log("items after Category filtering: " + $(items).length);

			switch(type) {
				case "1": 	// Medical and Veterinary
					items = $(items).filter("[data-type=1]");
					break;

				case "2": 	// Medical
				case "3": 	// Veterinary
					items = $(items).filter("[data-type=1], [data-type=" + type + "]");
					break;
			}

			$(items).last().toggleClass("end");
			
			// console.log("---------------------------");
			// console.log("Type: " + type);
			// console.log("items after type filtering: " + $(items).length);

			//-- Filter icon update
			var icon = $("#usetype option:selected").data("icon");
			if ( icon == "no-icon" ) {
				$("#usetype").closest(".filter-item").addClass("no-icon");
				$("#usetype").siblings(".icon").find("span").attr("class", "");
			} else {
				$("#usetype").closest(".filter-item").removeClass("no-icon");
				$("#usetype").siblings(".icon").find("span").attr("class", icon);
			}

			listPagination();
		}

		// Show products blocks
		function listPagination() {
			var pagination = pageStart + itemsPerPage;
			var hiddenItems = $(items).filter("[data-visible=false]");
			
			// console.log("---------------------------");
			// console.log("pagination: " + pagination);
			// console.log("pageStart: " + pageStart);
			// console.log("items: " + $(items).length);
			// console.log("hiddenItems: " + $(hiddenItems).length);

			if ( itemsPerPage < $(hiddenItems).length ) {
				for (var i = 0; i < itemsPerPage; i++) {
					$(hiddenItems).eq(i).attr("data-visible", "true");
				};
				pageStart = pagination;
				$(pager).attr("data-visible", "true");
			} else {
				$(hiddenItems).attr("data-visible", "true");
				pageStart = $(items).length;
				$(pager).attr("data-visible", "false");
			}
		}

		// Pagination
		listPagination();
		$(pager).click(function() {
			listPagination();
		});


		//-- Filter update
		$(filter).find("select").change(function(){
			pageStart = 0;
			$(items).last().toggleClass("end");
			items = $(list).find(".item");
			$(items).attr("data-visible", "false");
			itemsFilter();
		});

	});





	///////////////////////////////////////////////////////////////
	//FUNCIONES ON WINDOW LOAD ////////////////////////////////////
	///////////////////////////////////////////////////////////////
	$(window).load(function() {

		// Scrollbar **********************************************
		// $(".scroll").mCustomScrollbar({
		// 	axis: "y",
		// 	theme: "minimal-dark",
		// 	scrollInertia: 0
		// });


		// Video player *******************************************
		$("[data-dialog=video]").click(function() {
			var sv = $(this);
			videoPlayer(sv);
		});


		// // Image gallery ******************************************
		// $("[data-dialog=image]").click(function() {
		// 	var g = $(this).closest(".gallery");
		// 	gallerySlideshow(g);
		// });

	});





	///////////////////////////////////////////////////////////////
	//FUNCIONES ON WINDOW RESIZE //////////////////////////////////
	///////////////////////////////////////////////////////////////
	$(window).resize(function() {
		
		browserSize();

		bkgmulti();

		offCanvasToggle();

		// $.throttle( 250, function() {
		// });

	});



	
	
	///////////////////////////////////////////////////////////////
	//FUNCIONES ON DEVICE ORIENTATION CHANGE //////////////////////
	///////////////////////////////////////////////////////////////
	//$( window ).on( "orientationchange", function() {
	//});





	// $(document).mouseup(function (e) {
	//     var container = $(".menu.open");

	//     if (!container.is(e.target) // if the target of the click isn't the container...
	//         && container.has(e.target).length === 0) // ... nor a descendant of the container
	//     {
	// 		$("body").removeClass("noscroll");
	// 		$(".menu").removeClass("open");
	//     }
	// });
});











